import { Navbar, Nav } from 'react-bootstrap';
import React from 'react';
class Navigation extends React.Component {
    render() {
        return (
            <Navbar className="sticky-top" bg="light" expand="lg">
                    <Navbar.Brand href="#inicio">CSMD</Navbar.Brand>
                    <Nav.Link href="https://cloud.google.com/" className={"navbar-google"} target="_blank">Google Cloud</Nav.Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#enqueconsiste">En qué consiste</Nav.Link>
                            <Nav.Link href="#objetivos">Objetivos</Nav.Link>
                            <Nav.Link href="#paraquien">A quién se dirige</Nav.Link>
                            <Nav.Link href="#comofunciona">Qué estudiarás</Nav.Link>
                            <Nav.Link href="#docentes">Quién lo imparte</Nav.Link>
                            <Nav.Link href="#contacto">Contacto</Nav.Link>
                            {/*<Nav.Link href="https://twitter.com/csmd_upo" target="_blank" className="twitter_link">Twitter</Nav.Link>
                            <Nav.Link href="https://www.linkedin.com/company/master-csmd-upo/" target="_blank" className="facebook_link">Linkedin</Nav.Link>*/}
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        );
    }
}
export default Navigation;