import React from 'react';
const url = 'https://europe-west3-master-upo-csmd.cloudfunctions.net/mailer';
class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: { validation: false }, formValid: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const obj = {};
        if (event.target.name === 'validation') obj[event.target.name] = !this.state.value.validation;
        else obj[event.target.name] = event.target.value;
        this.setState({ value: Object.assign({}, this.state.value, obj) }, () => {
            this.setState({ formValid: this.checkValidity() }, () => {
                console.log(this.state.formValid);
            });
        });
    }

    checkValidity() {
        const st = this.state.value;
        console.log(this.state);
        return !!st.name && st.name !== '' && !!st.mobile && st.mobile !== '' && !!st.mail && st.mail !== '';
    }

    async handleSubmit(event) {
        event.preventDefault();
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.value)
        });
        console.log(response);
        window.location.href = "https://www.csmd.es/gracias.html";
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <p><label>Nombre y apellidos</label></p>
                <p><input name="name" type="text" placeholder="Escribe aquí tu nombre y apellidos" value={this.state.value.name} onChange={this.handleChange} required /></p>
                <p><label>Móvil</label></p>
                <p><input name="mobile" type="text" placeholder="Escribe aquí tu número de móvil" value={this.state.value.mobile} onChange={this.handleChange} required /></p>
                <p><label>Email</label></p>
                <p><input name="mail" type="email" placeholder="Escribe tu correo electrónico" value={this.state.value.mail} onChange={this.handleChange} required /></p>
                <p><label>Mensaje</label></p>
                <p><textarea name="msg" value={this.state.value.msg} onChange={this.handleChange} placeholder="Cuéntanos tus dudas y te ayudaremos" /></p>
                {/* <p className="checkbox"><input name="validation" type="checkbox" value={this.state.value.validation} onChange={this.handleChange} ></input> Acepto el Aviso legal y la Política de privacidad</p> */}
                <p><input disabled={!this.state.formValid} type="submit" value="Solicita información" onChange={this.handleChange} /></p>
            </form>
        );
    }
}
export default ContactForm;